<template>
  <div class="app-container">
    <div class="warpper">
      <Head
        :info="{
          title: dataList.title,
          created_at: dataList.created_at,
        }"
      />
      <PicGrid :cover_img="dataList.cover_img" />

      <div class="rich_media_content">{{ dataList.desc }}</div>
      <div class="act-info" v-if="dataList.is_activity === 1">
        <Act :dataList="dataList" />
      </div>
      <Card />
      <Analytics
        :info="{
          view_count: dataList.view_count,
          forward_count: dataList.forward_count,
        }"
      />
    </div>
    <!-- <pre>
      {{ shareData }}
    </pre> -->
  </div>
</template>
<script>
import Head from '../components/head'
import PicGrid from '../components/picGrid'
import Analytics from '../components/analytics'
import Act from '../components/act/index'
import wx from 'weixin-js-sdk'
import qs from 'qs'
import Card from '../components/card'
import Cookies from 'js-cookie'
export default {
  components: {
    Head,
    PicGrid,
    Analytics,
    Act,
    Card
  },
  data () {
    return {
      dataList: {},
      sys_id: '',
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    qrCodeUrl () {
      return location.origin + '/#/my/sign?sys_id=' + this.sys_id
    },
    setWxShare () {
      let _this = this
      let jsonData = {
        article_id: this.dataList.article_id,
        from_id: Number(Cookies.get('client_id')),
        relation_id: Number(this.dataList.relation_id)
      }

      let shareUrl = location.origin+ `/#${this.$route.path}?` + qs.stringify(jsonData)
      wx.ready(function () {
        //分享朋友圈
        wx.updateTimelineShareData({
          title: _this.dataList.title,
          link: shareUrl,
          imgUrl: 'https://szzh-hk.oss-cn-hangzhou.aliyuncs.com/default/logo/2021/0326/ab932202103261131019225.png',
          success: function () {
            // 设置成功
            _this.$axios.post('/wxc/article/forward', {
              article_id: _this.dataList.article_id,
              relation_id: Number(_this.dataList.relation_id) || 0,
              channel: 2
            })
          }
        })

        //转发好友
        wx.updateAppMessageShareData({
          title: _this.dataList.title, // 分享标题
          desc: _this.dataList.desc, // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://szzh-hk.oss-cn-hangzhou.aliyuncs.com/default/logo/2021/0326/ab932202103261131019225.png', // 分享图标
          success: function () {
            // 设置成功
            _this.$axios.post('/wxc/article/forward', {
              article_id: _this.dataList.article_id,
              relation_id: Number(_this.dataList.relation_id) || 0,
              channel: 1
            })
          }
        })

      })

    },
    getDataList () {
      let para = { article_id: Number(this.$route.query.article_id) }
      if (this.$route.query.relation_id) {
        para.relation_id = Number(this.$route.query.relation_id)
      }
      this.$axios.post('/wxc/article/detail', para).then(res => {
        this.dataList = res.data
        this.setWxShare()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  background: #fff;
  min-height: 100vh;
  padding: 20px 16px 12px;
}
.warpper {
  max-width: 677px;
  margin: 0 auto;
}
.rich_media_content {
  padding: 10px 0 20px;
}
.is-join {
  .line {
    text-align: center;
  }
}
.qrcode {
  text-align: center;
  padding: 20px;
}
.act-info {
  margin-bottom: 20px;
}
</style>