<template>
  <div class="head">
    <div class="title">{{ info.title }}</div>
    <div class="meta-content">
      <span class="name">{{getCompanyName()}}</span>
      <span class="time">{{info.created_at | dateFormat('MM月DD日 H时')}}</span>
    </div>
  </div>
</template>
<script>
import { getSite } from '@/utils/auth'
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    getCompanyName(){
      return getSite().companyName
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  font-weight: 400;
}
.meta-content {
  margin-bottom: 22px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.55);
  span {
    margin-right: 10px;
  }
}
</style>