<template>
  <van-row gutter="10">
    <van-col span="8" v-for="(item, index) in imgsArr" :key="index">
      <div class="item2">
        <img :src="item" @click="showImg(index)" />
      </div>
    </van-col>
  </van-row>
</template>
<script>
import { ImagePreview } from 'vant'
export default {
  props: {
    cover_img: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      imgsArr: []
    }
  },
  watch: {
    cover_img () {
      this.imgsArr = this.cover_img.split('|')
    }
  },
  mounted () {
    this.imgsArr = this.cover_img.split('|')
  },
  methods: {
    showImg (index) {
      ImagePreview({
        images: this.imgsArr,
        startPosition: index
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item2 {
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
}
.item2:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.item2 img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>