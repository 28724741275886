<template>
  <div class="act-box">
    <template v-if="dataList.goods_no">
      <van-cell-group>
        <van-swipe :autoplay="3000" width="100%">
          <van-swipe-item v-for="(image, index) in coverImgArr" :key="index">
            <img style="width: 100%" :src="image" />
          </van-swipe-item>
        </van-swipe>

        <div class="act-info">
          <div class="title">{{ goodsDetail.name }}</div>
          <div class="desc gray font12">{{ goodsDetail.desc }}</div>
          <div class="price">
            ¥ {{ goodsDetail.sale_price / 100 || '0.00' }}
          </div>
        </div>
      </van-cell-group>
    </template>

    <van-cell-group
      class="block"
      v-if="dataList.form_id && dataList.join_status === 0"
      title=" "
    >
      <template v-for="(item, index) in formItems">
        <van-field
          v-if="item.type === 'text'"
          :key="index"
          v-model="form[item.code]"
          :label="item.name"
          placeholder="请输入"
        />
        <van-field
          v-if="item.type === 'textarea'"
          :key="index"
          rows="2"
          autosize
          type="textarea"
          v-model="form[item.code]"
          :label="item.name"
          maxlength="50"
          placeholder="请输入"
        />
        <VantFieldDate
          v-if="item.type === 'date'"
          v-model="form[item.code]"
          :label="item.name"
          type="datetime"
          :key="index"
          placeholder="请选择"
          is-link
        />

        <VantFieldSelect
          :key="index"
          v-if="item.type === 'select'"
          v-model="form[item.code]"
          :label="item.name"
          :columns="item.optionArr"
          placeholder="请选择"
          is-link
        />

        <van-field :label="item.name" :key="index" v-if="item.type === 'radio'">
          <template #input>
            <van-radio-group v-model="form[item.code]" direction="horizontal">
              <van-radio :name="val" v-for="val in item.optionArr" :key="val">{{
                val
              }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          :label="item.name"
          :key="index"
          v-if="item.type === 'checkbox'"
        >
          <template #input>
            <van-checkbox-group
              v-model="form[item.code]"
              direction="horizontal"
            >
              <van-checkbox
                :name="val"
                shape="square"
                v-for="val in item.optionArr"
                :key="val"
                >{{ val }}</van-checkbox
              >
            </van-checkbox-group>
          </template>
        </van-field>
      </template>
    </van-cell-group>

    <div class="is-join" v-if="dataList.join_status === 1">
      <div class="line">
        <van-icon name="success" /><span>你已经参加了该活动，请到店确认</span>
      </div>
      <div class="qrcode">
        <VueQr
          :correctLevel="3"
          :autoColor="false"
          :text="qrCodeUrl()"
          :size="128"
          :margin="0"
          :logoMargin="3"
        />
      </div>
      <van-button type="info" block @click="$router.push({ name: 'MyAct' })"
        >查看更多我的活动</van-button
      >
    </div>
    <div class="is-join" v-if="dataList.join_status === 2">
      <div class="line">
        <van-icon name="success" /><span>你已经参加了该活动，已确认到店</span>
      </div>
      <van-button type="info" block @click="$router.push({ name: 'MyAct' })"
        >查看更多我的活动</van-button
      >
    </div>

    <!-- <van-button disabled type="info" block>活动未开始</van-button>
    <van-button disabled type="info" block>活动已结束</van-button> -->

    <van-button
      type="info"
      block
      @click="onSubmit"
      v-if="dataList.join_status === 0"
      >参与活动</van-button
    >

    <div class="join" v-if="dataList.is_join_data_visible === 1">
      <div class="tit">参与信息</div>
      <div class="join-main">
        <div class="head">
          <div class="info">
            已参与 <strong>{{ dataList.join_count }}</strong> 人
          </div>
          <div class="info">
            <strong>{{ dataList.view_count }}</strong> 次浏览
          </div>
        </div>
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in dataList.join_list"
            :key="index"
          >
            <van-image
              :src="item.wechat_avatar"
              round
              width="28px"
              height="28px"
            />
            <div class="nickname">
              {{ $Base64.decode(item.wechat_nickname) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VantFieldDate from '@/components/form/VantFieldDate'
import VantFieldSelect from '@/components/form/VantFieldSelect'
import VueQr from 'vue-qr'
import wx from 'weixin-js-sdk'
import { Notify } from 'vant'
export default {
  components: {
    VantFieldDate,
    VantFieldSelect,
    VueQr
  },
  props: {
    dataList: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      goodsDetail: {},
      formItems: [],
      form: {},
      goods_url: '',
      addressList: [],
      coverImgArr: [],
      sys_id: 0
    }
  },
  mounted () {
    if (this.dataList.goods_no) {
      this.getGoodsDetail()
      // this.getAddress()
    }
    if (this.dataList.form_id) {
      this.getFormItems()
    }

  },
  methods: {
    getAddress () {
      this.$axios.post('/wxc/address/lists', {
        count: 1
      }).then(res => {
        this.addressList = res.data.list
      })
    },
    qrCodeUrl () {
      return location.origin + '/#/my/sign?sys_id=' + this.dataList.join_sys_id
    },
    onSubmit () {
      let _this = this
      this.$axios.post('/wxc/article/join', {
        article_id: this.dataList.article_id,
        relation_id: this.dataList.relation_id,
        data_list: this.form,
        address_id: 4
      }).then(res => {
        if (res.code === 200) {
          if (res.data.status === 0) {
            //如关联商品，需要在线支付
            wx.chooseWXPay({
              timestamp: res.data.payment.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res.data.payment.nonceStr, // 支付签名随机串，不长于 32 位
              package: res.data.payment.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res.data.payment.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: res.data.payment.paySign, // 支付签名
              success: function () {
                // 支付成功后的回调函数
                // _this.$router.push({ name: 'MyAct' })
                _this.dataList.join_status = 1
                _this.dataList.join_sys_id = res.data.sys_id
              },

            })
          } else {
            this.dataList.join_status = 1
            this.sys_id = res.data.sys_id
          }

        } else {
          Notify({ type: 'danger', message: res.msg })
        }
      })
    },
    getFormItems () {
      this.$axios.post('/wxc/form/detail', {
        form_id: this.dataList.form_id,
        status: 1
      }).then(res => {
        res.data.item_list.map((item, index) => {
          let val = item.default_val || ''

          this.$set(this.form, item.code, val);

          this.form[item.code] = item.default_val || ''
          res.data.item_list[index].optionArr = item.option_val.split('|')

          if (item.type === 'checkbox') {
            this.form[item.code] = []
          }

          // if(item.type === 'checkbox' || item.type === 'radio'){
          //   this.form[item.code] = item.option_val.split('|')[0]
          // }

        })
        this.formItems = res.data.item_list
        this.$forceUpdate()
      })
    },
    getGoodsDetail () {
      this.$axios.post('/wxc/goods/detail', {
        goods_no: this.dataList.goods_no
      }).then(res => {
        this.goodsDetail = res.data
        this.goods_url = location.origin + '/#/code/?goods_no=' + this.dataList.goods_no + '&relation_id=' + this.dataList.relation_id
        this.coverImgArr = res.data.cover_img.split('|')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.act-box {
  padding: 40px 0;
}
.is-join {
  .line {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
}
.qrcode {
  text-align: center;
  padding: 20px;
}
.join {
  margin-top: 20px;
  // background-color: #fafafa;
  padding: 10px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .tit {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }
  .join-main {
    .head {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .info {
        flex: 1;
        text-align: center;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px;
      .list-item {
        width: 20%;
        text-align: center;
        overflow: hidden;
        margin-bottom: 10px;
      }
      .nickname {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        overflow: hidden;
      }
    }
  }
}

::v-deep .van-radio {
  width: 100%;
  padding: 5px;
}
.act-info {
  padding: 10px 16px;
  background: #fff;
  .title {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 3px;
  }
  .desc {
    line-height: 1.6;
  }
}
.price {
  padding-top: 20px;
  color: #ee0a24;
  font-weight: bolder;
  flex: unset;
}
</style>