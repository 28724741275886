<template>
  <div class="analytics">
    <span class="info">{{info.view_count}} 阅读</span> ·
    <span class="info">{{info.forward_count}} 分享</span>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.analytics {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  .info {
    padding: 0 3px;
  }
}
</style>